import GET_ARTICLES from "../class/getArticles";

const [d] = [document];

class Blocks {
  constructor () {
    this.init();
  }
  init(){
    this.pickup();
    this.uservoice();
    this.news();
  }

  pickup() {
    const container = d.querySelector('.mainvisual__pickup');
    const getArticle = new GET_ARTICLES('.mainvisual__pickup[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
      <a href="${item.href}" class="pickup-item"><img src="${item.thumb}" alt="${item.title}" class="pickup-item__thumb"></a>
      <!-- /.pickup-item -->`;
    getArticle.render().then( ([[items]]) => (items.length == 0)? container.remove(): false);
  }

  uservoice () {
    const container = d.querySelector('.block-uservoice');
    const getArticles = new GET_ARTICLES('.block-uservoice__list[data-plugin="getArticles"]');
    getArticles.makeItem = item => `
    <li class="block-uservoice-item" data-lazydisp="bottom">
      <a href="${item.href}" class="block-uservoice-item__link">
        <figure class="block-uservoice-item__thumb">
          <img src="${item.thumb}" alt="${item.title}" width="100%" loading="lazy" class="block-uservoice-item__thumb-img">
        </figure>
        <div class="block-uservoice-item__body">
          <div class="block-uservoice-item__body-inner">
            <h5 class="title">${item.title}</h5>
          </div>
        </div>
        <!-- /.block-uservoice-item__body -->
      </a>
      <!-- /.block-uservoice-item__link -->
    </li>`;
    getArticles.render().then( ([[items]]) => (items.length == 1)? container.remove(): false);
  }

  news () {
    const getArticles = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
    getArticles.makeItem = (item,content) => `
    <li class="block-${content}-item" data-lazydisp="bottom">
      <a href="${item.href}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span>
            <span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${item.title.str_truncate(24)}</h4>
        </div>
      </a>
    </li>`;
    getArticles.render();
  }
}

const domReady = TPL => {
  new Blocks;
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}